.table {
    &-container {
        width: 100%;

        .MuiTableCell-stickyHeader {
            background-color: #efefef;
            font-size: 16px;
            font-weight: bold;
        }
    }

    &-top {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        a {
            margin-left: 20px;
        }
    }

    &-filters {
        border: 1px solid #eee;
        border-radius: 5px;
        height: 0;
        opacity: 0;
        transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out 0.1s;
        visibility: hidden;

        &.__visible {
            height: auto;
            margin-bottom: 20px;
            opacity: 1;
            padding: 20px;
            visibility: visible;
        }

        &-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
        }
    }
}
