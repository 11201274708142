$loader-color: #3f51b5;

.loader {
    height: 100%;
    width: 100%;

    &:not(.__section) {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.__section {
        min-height: 500px;
        position: relative;
    }

    &-elements {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        margin: 0 auto;
        width: 80%;

        li {
            animation: loader 1s ease-in-out infinite alternate;
            background: $loader-color;
            border-radius: 2px;
            box-shadow: 0px 0px 5px $loader-color;
            display: inline-block;
            height: 30%;
            margin-left: 1%;
            max-height: 30px;
            max-width: 20px;
            opacity: 0;
            transform: skew(-25deg, 0deg) scale(0.1);
            transition: all 0.4s;
            width: 20%;

            &:nth-child(2) {
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                animation-delay: 0.4s;
            }
            &:nth-child(4) {
                animation-delay: 0.6s;
            }
            &:nth-child(5) {
                animation-delay: 0.8s;
            }
            &:nth-child(6) {
                animation-delay: 1s;
            }
            &:nth-child(7) {
                animation-delay: 1.2s;
            }
        }
    }
}

@keyframes loader {
    to {
        opacity: 1;
        transform: skew(-25deg, 0deg) scale(1);
    }
}
