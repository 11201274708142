html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}

table {
    border-spacing: 0;
}

a {
    background-color: transparent;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
        outline: 0;
        outline-color: transparent;
        text-decoration: none;
    };
}


i,
em {
    font-style: normal;
}

b,
strong {
    font-weight: bold;
}

:focus {
    outline: 0;
}

fieldset,
img {
    border: 0;
}

hr {
    box-sizing: content-box;
    height: 0;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
}

html,
body {
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    text-rendering: auto;
    font-smooth: always;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

ul {
    list-style: none;
}

body {
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    text-shadow: none;
}

sup {
    position: relative;
    top: -.4em;
    vertical-align: baseline;
}

.navbar {
    border-radius: 0 !important;
}
