.header {
    position: relative;
    z-index: 1;

    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
