.login {
    max-width: 500px;
    margin: 100px auto 0;

    &-title {
        margin-bottom: 20px;
        font-size: 30px;
        text-align: center;
    }
}
