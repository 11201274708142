.main {
    display: flex;
    padding: 20px 0;

    &-content {
        margin: 0 20px;
        position: relative;
        width: 100%;
    }

    .companies {
        margin-top: 30px;
    }

    &-menu {
        .active {
            background: #e7e7e7;
        }
    }
}

.info {
    &-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &-block {
        margin-bottom: 30px;
        width: 50%;

        &-label {
            color: #a9a9a9;
            font-size: 12px;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        &-txt {
            font-size: 16px;
            min-height: 19px;

            &-item {
                align-items: center;
                display: flex;
                justify-content: flex-start;

                &::first-letter {
                    text-transform: capitalize;
                }

                &-label {
                    margin-right: 5px;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                &-value {
                    color: #4644a9;
                }
            }

            &-row:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}

.form {
    + .form {
        margin-top: 50px;
    }

    &-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;

        + .form-block {
            &::after {
                display: none !important;
            }
        }
    }

    &-subtitle {
        color: darkgrey;
        font-size: 16px;
        font-style: italic;
        margin-bottom: 20px;
    }

    &-block {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 20px;

        &:nth-of-type(n + 2) {
            position: relative;

            &::after {
                background: #eee;
                content: "";
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    &-item {
        width: 50%;

        &-container {
            margin-bottom: 20px;
            width: 90%;
        }

        .__with-icon {
            position: relative;

            svg {
                left: 15px;
                opacity: 0.55;
                position: absolute;
                top: 16px;
                z-index: 1;
            }

            .MuiOutlinedInput-input {
                padding-left: 45px;
            }

            .MuiInputLabel-shrink {
                transform: translate(-20px, -6px) scale(0.75);
            }

            .MuiFormLabel-root {
                left: 35px;
            }
        }
    }

    &-buttons {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 50px 0;

        .button-container {
            margin-right: 10px;

            button {
                &.submit {
                    background: #368231;

                    &:disabled {
                        background: rgba(#368231, 0.5);
                    }
                }

                &.delete {
                    background: #d14529;

                    &:disabled {
                        background: rgba(#d14529, 0.5);
                    }
                }
            }
        }
    }
}

.button-container {
    position: relative;
}
