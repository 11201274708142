@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

@import "base/reset";

@import "components/login";
@import "components/header";
@import "components/admin";
@import "components/table";
@import "components/loader";

#root {
    > .loader.__section {
        min-height: 100vh;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
